.nav {
  width: 100%;
  color: $white;
  background-image: linear-gradient(
    145deg,
    rgba($color-primary-mid, 1),
    rgba($color-primary-light, 1)
  );
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $phone) {
    flex-direction: column;
  }
  &__links {
    width: 15rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $phone) {
      width: 25rem;
    }

    & a {
      text-decoration: none;
    }
    & img {
      width: 4rem;
      transition: all 0.2s;
      &:hover {
        transform: scale(1.2);
      }
      @media (max-width: $phone) {
        width: 6rem;
      }
      //ie fix
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        height: 4rem;
      }
    }
  }

  //this menu is saved for later, when we have more nav options.
  // &__menu {
  //     position:relative;
  //     transition:all .2s;

  //     &::before, &::after {
  //         width:3.4rem;
  //         content: " ";
  //         height: 0.2rem;
  //         position:absolute;
  //         background: $white;
  //     }
  //     &::before {
  //         top:-.1rem;
  //     }

  //     &:hover {
  //         cursor:pointer;
  //     }
  //     &:hover::before {
  //         transform:translateY(-.3rem);
  //     }
  //     &:hover::after {
  //         transform:translateY(.3rem);
  //     }
  // }
}
