.intro {
    background-image: linear-gradient(to bottom, rgba($white, .9), rgba($white, .9)), url(/img/bird-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    padding-top:10rem;
    color:$dark-grey-2;
    @media (max-width: $phone){
        padding-top:18rem;
    }

    
    &__content {
        display:flex;
        align-items:center;
        flex-direction: column;
    }

    &__img {
        border-radius:50%;
    }
}
.web {
    background: $dark-grey-2;
    color:$light-grey-1;
    position:relative;
}
.writing {
    position:relative;
}
.footer {
    background:$dark-grey-1;
    color:white;
    font-weight:300;
    display:flex;
    justify-content: center;

    &__text {
        text-align:center;
    }
    &__link {
        color:$color-primary-mid;
    }
    &__link:hover {
        color:$color-primary-light;
    }
}