.project-group {
  height: 51rem;
  width: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  @media (max-width: $tablet) {
    height: auto;
  }
}
.project {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 1rem 0 5rem 0;
  position: absolute;
  width: 100%;
  opacity: 0;
  transition: all ease-in 0.4s;
  @media (max-width: $tablet) {
    position: relative;
    opacity: 1;
  }

  &--right {
    transform: translateX(60%);
  }
  &--left {
    transform: translateX(-60%);
  }

  &--shown {
    opacity: 1;
    z-index: 2;
    transform: translateX(0);
  }

  &__title {
    display: block;
    font-weight: 400;
    font-size: 3rem;
    @media (max-width: $tablet) {
      text-align: center;
      font-size: 3.5rem;
    }
  }

  &__text {
    margin-top: 2rem;
    flex: 3;
    min-width: 33rem;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      max-width: 600px;
    }
  }

  &__subtitle {
    display: block;
    font-size: 2.2rem;
    @media (max-width: $phone) {
      font-size: 3rem;
    }
  }

  &__preview {
    margin: 3rem;
    flex: 2;
    display: flex;
    flex-direction: column;
  }
  &__img {
    border-radius: 0.8rem;
    box-shadow: 0.3rem 0.3rem 0.5rem 0px $black;
    width: 100%;
    transition: all 0.3s;
    min-width: 33rem;
    @media (max-width: $tablet) {
      width: 50%;
      margin: 0 auto;
      display: block;
    }
    @media (max-width: $phone) {
      width: 80%;
    }
    &:hover {
      transform: scale(1.05);
    }
    &--no-border {
      border: none;
      box-shadow: none;
    }
  }
  &__buttons {
    display: flex;
    height: 5rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    min-width: 34rem;
  }
}
