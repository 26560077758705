body {
    color: $dark-grey-1;
    font-size:1.6rem;
    font-family: $Jsans;
    font-weight: 400;
    line-height:1.5;
    @media (max-width: $phone){
        font-size:2.5rem;
    }

}
p {
    padding:.75rem;
}
a {
    text-decoration:none;
    color:inherit;
}

.page-title {
    font-size:3rem;
    font-weight:300;
    transition: all .2s;
    @media (max-width: $phone){
        font-size:5rem;
    }
    
    &__subtitle {
        font-size:2.4rem;
        font-weight:300;
        display:inline-block;
        @media (max-width: $phone){
            font-size:4rem;
        }
    }

    &:hover {
        transform: scale(1.1);
    }
}
.h3 {
    margin: 3rem 0;
    font-weight:400;
}
    
