//css reset
*, *::after, *::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    
}
html {
    font-size: 62.5%; //this makes 1 rem 10px.
    @media (max-width: $tablet){
        font-size:57.5%; //1rem = 9px
    }
    @media (max-width:$phone){
        font-size:42.5%; //1rem = 7px
    }
}
body {
    box-sizing: border-box;
}