//colors
$white: #fff;
$light-grey-1: #e5e5e5;
$light-grey-2:#ccc;
$mid-grey-1: #999;
$mid-grey-2: #777; 
$dark-grey-1: #666; //main text color
$dark-grey-2: #191919;
$black: #000;

 $color-primary-light: rgb(270, 197, 72);
$color-primary-mid: rgb(240, 167, 42);
// $color-primary-dark: ;


//fonts
$Jsans: 'Josefin Sans', sans-serif;

//breakpoints
$phone : 37.5em; //600px
$tablet: 56.25em; //850px
