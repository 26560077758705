.section {
  padding-top: 5rem;
  padding-bottom: 5rem;

  &__title {
    font-size: 5rem;
    font-weight: 300;
    text-align: center;
  }
  &__text {
    font-size: 2.2rem;
    text-align: center;
    //ie fix
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      max-width: 1200px;
    }
  }
}
