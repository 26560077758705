.btn {
    padding: 1rem;
    color:inherit;
    text-decoration:none;
    margin:.5rem;
    margin-right:1.5rem;
    min-width:16rem;
    text-align:center;
    transition: all .3s;
    box-shadow:.3rem .3rem .5rem 0px $black;
    border: .3rem solid $light-grey-1;
    border-radius:.8rem;
    &:hover {
        transform:scale(1.1);
    }
    &--outline {
        background:inherit;
        box-shadow:.3rem .3rem .5rem 0px $black;
    }
    &--outline:hover {
        background:$light-grey-1;
        color:$dark-grey-2;
    }
    &--read-more {
        border-color:$color-primary-mid;
        color: $color-primary-mid;
        box-shadow:.3rem .3rem .5rem 0px $light-grey-1;
    }
    &--read-more:hover {
        background:$color-primary-mid;
        color:$white;
    }
}